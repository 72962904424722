@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-light */
@font-face {
	font-family: 'ITC Avant Garde';
	src: url(../assets/fonts/ITCAvantGardeStd-Bk.woff2) format('woff2');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

/* font-medium */
@font-face {
	font-family: 'ITC Avant Garde';
	src: url(../assets/fonts/ITCAvantGardeStd-Md.woff2) format('woff2');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

/* font-semibold */
@font-face {
	font-family: 'ITC Avant Garde';
	src: url(../assets/fonts/ITCAvantGardeStd-Demi.woff2) format('woff2');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

/* font-bold */
@font-face {
	font-family: 'ITC Avant Garde';
	src: url(../assets/fonts/ITCAvantGardeStd-Bold.woff2) format('woff2');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

/* ===================
Colors - https://coolors.co/ffffff-f2f2f2-f0b323-e1007e-00a9e0-525ca3-3a3a3b-14141a
white: '#ffffff',
grey: '#f2f2f2',
yellow: '#f0b323',
pink: '#e1007e',
cyan: '#00a9e0',
purple: '#525ca3',
teal: #00b2a9,
onyx: '#3a3a3b',
night: '#14141a', 
=================== */

@layer base {
	html {
		@apply font-light text-night;
	}

	h1,
	.h1 {
		@apply mb-6 text-center text-3xl/snug font-semibold uppercase text-purple first:mt-0 last:mb-0 print:text-left print:normal-case md:text-left lg:text-4xl/snug;
	}

	h2,
	.h2 {
		@apply mb-4 mt-8 text-center text-2xl/snug font-semibold text-purple first:mt-0 last:mb-0 print:text-left print:normal-case md:text-left lg:text-3xl/snug;
	}

	h3,
	.h3 {
		@apply mb-4 mt-8 text-center text-xl/snug font-semibold text-purple first:mt-0 last:mb-0 print:text-left print:normal-case md:text-left lg:text-2xl/snug;
	}

	h4,
	.h4 {
		@apply mb-4 mt-8 text-center text-lg font-semibold text-purple first:mt-0 last:mb-0 print:text-left print:normal-case md:text-left;
	}

	a {
		@apply cursor-pointer transition-all duration-200 ease-in-out;
	}

	p {
		@apply my-4 leading-relaxed print:text-sm;
	}

	p:first-child,
	ol:first-child,
	ul:first-child {
		@apply mt-0;
	}

	p:last-child,
	ol:last-child,
	ul:last-child {
		@apply mb-0;
	}

	ol {
		@apply my-4 list-decimal pl-4  print:text-sm;
	}

	ul {
		@apply my-4 list-disc pl-4  print:text-sm;
	}

	li {
		@apply ml-4 pl-2 leading-relaxed  print:text-sm;
	}
}

@layer utilities {
	.text-shadow-night {
		text-shadow: 0px 0px 8px theme(colors.night / 95%),
			0px 0px 5px theme(colors.night / 95%),
			0px 0px 2px theme(colors.night / 95%);
	}
}

@layer components {
	.link {
		@apply underline underline-offset-4 hover:text-purple;
	}

	.link-purple {
		@apply text-purple underline underline-offset-4 hover:text-night;
	}

	.link-pink {
		@apply text-pink underline underline-offset-4 hover:text-purple;
	}

	.link-white {
		@apply text-white underline underline-offset-4 hover:text-white/80;
	}

	.breadcrumb {
		@apply mb-8 mt-2.5 hidden flex-row items-center justify-start text-sm print:hidden md:flex;
	}

	.btn {
		@apply my-6 w-fit cursor-pointer rounded-full bg-purple px-8 py-2.5 text-white transition-all duration-200 ease-in-out first:mt-0 last:mb-0 hover:scale-103 hover:bg-purple-dark;
	}

	.mat {
		@apply mt-6 text-sm font-light leading-relaxed;
	}

	.further-info {
		@apply mt-8 text-center font-semibold uppercase;
	}

	/* width */
	.custom-scroll::-webkit-scrollbar {
		@apply w-2.5;
	}

	/* Track */
	.custom-scroll::-webkit-scrollbar-track {
		@apply bg-night/20;
	}

	/* Handle */
	.custom-scroll::-webkit-scrollbar-thumb {
		@apply bg-night/50;
	}

	/* Handle on hover */
	.custom-scroll::-webkit-scrollbar-thumb:hover {
		@apply bg-night/80;
	}

	.autocomplete-item {
		@apply cursor-pointer border-b border-night bg-grey px-2.5 py-4 text-sm transition-all duration-100 ease-in-out hover:bg-cyan hover:text-white md:py-1;
	}

	.autocomplete-active {
		@apply bg-cyan text-white;
	}

	.vaccine-item {
		@apply mb-4 flex flex-row flex-nowrap items-center justify-start gap-2 text-sm font-semibold before:aspect-[4/7] before:h-3 before:bg-chevron-right before:bg-contain before:bg-no-repeat last:mb-0;
	}
}
